import React,{useEffect}from "react";
import comingSoonImage from "../../../static/assets/images/what/comingSoon.png";
import { Container } from "react-bootstrap";
import 'aos/dist/aos.css';
import AOS from 'aos';
const ComingSoon = () => {
    useEffect(() => {
        AOS.init({
          duration : 2000
        })
    })
    return (
        <section>
            <Container>
                <div className="text-center" data-aos="fade-right">
                    <img src={comingSoonImage} alt="coming-soon" className="coming-soon-width" />
                </div>
            </Container>
        </section>
    )
}
export default ComingSoon